import React from "react"

import DarkBlue1 from '../../images/dividers/dark_blue_1.svg'
import LightGray1 from '../../images/dividers/light_gray_1.svg'
import LightBlue1 from '../../images/dividers/light_blue_1.svg'
import MediumGray1 from '../../images/dividers/medium_gray_1.svg'

const Divider = ((props) => {
    function renderSwitch(param) {
        switch(param) {
            case 'darkBlue1':
                return DarkBlue1
            case 'lightBlue1':
                return LightBlue1
            case 'lightGray1':
                return LightGray1;
            case 'mediumGray1':
                return MediumGray1;
            default:
                return;
        }
    }

return(
    <div>
        <img src={renderSwitch(props.color)} className="m-0" alt="divider" />
    </div>
)
})

export default Divider;