import React from 'react';
import Layout from '../components/layout';

import Divider from '../components/Divider';
import Seo from '../components/seo'

export default function Contact() {
  return (
    <Layout>
      <Seo title="Contact Us" />
    <div id="about" className="relative">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-18 flex content-center">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
          <h1 className="text-3xl lg:text-4xl font-extrabold text-cahoot-blue-med leading-5 tracking-tight font-header text-center">
            Contact Us
          </h1>
        </div>
      </div>
      <div className="md:-mt-12 lg:-mt-36">
        <Divider color="lightGray1" />
      </div>
      {/* Content start */}
      <div className="bg-cahoot-white -mt-10 pb-28 px-6 lg:px-0">
        <div className="lg:mx-auto lg:max-w-3xl font-text">
          <p className="mt-10 pt-10">
              Cahoot is an exciting new product and we hope that you are interested in learning more! Please complete the form below and we will respond to you
              as quickly as possible. 
          </p>
        <form
                  name="contact_form"
                  className="flex flex-col w-full pt-10"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <input type="hidden" name="form-name" value="contact_form" />
                  <div class="hidden">
                    <label>
                      Don’t fill this out if you’re human:{" "}
                      <input name="bot-field" />
                    </label>
                  </div>
                  <div className="md:flex flex-row">
                    <div className="min-w-0 flex-1 md:pr-2">
                      <label htmlFor="cta_first_name" className="sr-only">
                        First name
                      </label>
                      <input
                        id="cta_first_name"
                        name="cta_first_name"
                        type="text"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                    placeholder="First name"
                    required
                      />
                    </div>
                    <div className="min-w-0 flex-1 pt-2 md:pt-0">
                      <label htmlFor="cta_last_name" className="sr-only">
                        Last name
                      </label>
                      <input
                        id="cta_last_name"
                        name="cta_last_name"
                        type="text"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                        placeholder="Last name"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="min-w-0 flex-1 pt-2">
                      <label htmlFor="cta_company" className="sr-only">
                        Company Name
                      </label>
                      <input
                        id="cta_company"
                        name="cta_company"
                        type="text"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                        placeholder="Company"
                      />
                    </div>
                  </div>
                  <div className="md:flex flex-row">
                    <div className="min-w-0 flex-1 pt-2 md:mr-2">
                      <label htmlFor="cta_phone" className="sr-only">
                        Phone
                      </label>
                      <input
                        id="cta_phone"
                        name="cta_phone"
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                        placeholder="Phone (e.g. 555-555-5555)"
                      />
                    </div>
                  {/* </div>
                  <div className="flex"> */}
                    <div className="min-w-0 flex-1 pt-2">
                      <label htmlFor="cta_email" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="cta_email"
                        name="cta_email"
                        type="email"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="min-w-0 flex-1 pt-2">
                      <label htmlFor="cta_message" className="sr-only">
                        Your message
                      </label>
                      <textarea
                        id="cta_message"
                        name="cta_message"
                        className="block w-full border border-1 rounded-md px-5 py-3 font-text text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med"
                    placeholder="Your message"
                    rows="4"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-row pt-10 justify-center">
                    
                  <div className="flex mt-4">
                    <button
                      type="submit"
                      className="block w-full rounded-md font-text border border-transparent px-5 py-3 bg-cahoot-blue-med text-base font-bold text-cahoot-white hover:text-cahoot-white shadow hover:bg-cahoot-blue-dark focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med sm:px-10"
                    >
                      Send your message
                    </button>
                  </div>
                  </div>
                </form>
        </div>
      </div>
      </div>
    </Layout>
  )
}